 
import { TranslationView } from "@levelapp/softfabric-ui";
import SsoOperation from "src/Transfer/SsoOperations";

interface SsoRedirectionProps {
  redirect: string;
};

const addSsoKey = async (redirect: string) => {
  try {
    const tokens = localStorage.getItem('TOKEN');
    const operation = new SsoOperation();

    if (tokens) {
      const objTokens = JSON.parse(tokens);

      const formattedTokens = {
        token: objTokens.access_token,
        refreshToken: objTokens.refresh_token,
      }

      const key = await operation.post({
        content: JSON.stringify(formattedTokens),
      });

      window.location.href = `${process.env.REACT_APP_ADMIN_V2_URL}/provider?redirect=${redirect}&key=${key}`;
    }
  } catch {

  }
}

const SsoRedirection = ({
  redirect
}: SsoRedirectionProps) => {

  addSsoKey(redirect);

  return (
    <TranslationView>sso.redirection</TranslationView>
  )
};

export default SsoRedirection;